<template>
	<div id="app">
		<Heading :data="data"/>
		<Grid    :data="data" :gallery="$root.$data.images"/>
		<Footer  :data="data" />
	</div>
</template>

<script>
import data from './assets/data.json';
export default {
	name: 'App',
	data () {
		return {
			data: JSON.parse( JSON.stringify( data ) )
		}
	},
}
</script>
<style lang='sass' src="./css/style.sass"></style>
