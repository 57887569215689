<template>
	<div class="weather-box grid-item">
		<div class="wrapper">
			<h3>Været nå</h3>
			<div class="icon">
				<img v-if="icon" :src="'/icons/' + icon  + '.svg'" />
			</div>
			<div class="weather-info">
				<p class="celsius"><b>Temperatur: {{ Math.round(temperature)  }}</b></p>
				<p class="small">Vindstyrke: {{ wind }} m/s</p>
			</div>
			<p class="extra-info"><b>Sist oppdatert:</b> <br> {{ lastUpdated }}</p>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
moment.locale('nB');

export default {
	props: {
		data: {
			type: Object
		}
	},
	data () {
		return {
			wind: 0,
			temperature: 0,
			lastUpdated: '–',
			icon: ''
		}
	},
	created() {
		this.getCurrentWeather();
	},
	methods: {
		getCurrentWeather() {
			const that = this;
			const weatherURL = `https://api.met.no/weatherapi/nowcast/2.0/complete?lat=${this.data.weatherCoords.lat}&lon=${this.data.weatherCoords.long}`;

			axios.get( weatherURL )
			.then( function ( response ) {
				if ( response.data ) {
					const currentWeather   = response?.data?.properties?.timeseries[0];
					const currentStats     = currentWeather.data.instant.details;
					const currentStatsIcon = currentWeather.data.next_1_hours.summary.symbol_code;
					const currentStatsTime = ( currentWeather.time ) ? moment( currentWeather.time ) : false;

					if ( currentWeather ) {
						that.icon = currentStatsIcon;
						that.temperature = currentStats.air_temperature;
						that.wind = currentStats.wind_speed;
						that.lastUpdated = currentStatsTime.format( 'D. MMMM YYYY' ) + ' kl.' +  currentStatsTime.format( 'HH:mm' );
					}
				}
			} )
			.catch( function ( error ) {
				console.log( 'error', error );
			} );
		}
	}
}
</script>
<style scoped lang='sass' src="./style.sass"></style>
