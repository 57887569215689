import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false

/*
	Vue filters
*/

import moment from 'moment';
moment.locale('nB');
Vue.filter( 'formatDate', function( value, format = 'D. MMMM YYYY' ) {
	if ( value ) {
		return moment( value, 'YYYYMMDD' ).format( format );
	}
} );

Vue.filter( 'formatTime', function( value ) {
	if ( value ) {
		return 'kl.' + moment( value, 'HHmmss' ).format( 'HH:mm' )
	}
} );

Vue.filter( 'capitalize', function( text ) {
	return text.charAt(0).toUpperCase() + text.substring( 1, text.length )
} );


/*
	Auto add all components in components folder
*/
const files = require.context('./components', true, /\.vue$/i);
files.keys().map( ( key ) => {
	let filename = key.split('/').pop().split('.')[0];
	Vue.component( filename, files( key ).default );
});


/*
	Auto add all images
*/
const images = require.context('../public/images/', false);
let imageArray = {};
images.keys().map( ( path ) => {
	let folder = path.split('_')[0].replace('./', '');

	if ( !imageArray[folder] ) {
		imageArray[folder] = [];
	}

	path = path.replace('./', 'images/');
	imageArray[folder].push({
		src: path,
		date: folder,
		time: path.split('_')[1].split('.')[0]
	});
});

let sortedImages = [];
Object.entries( imageArray ).forEach( ( [ date, images ] ) => {
	images.sort( function( a, b ) {
		return b.time - a.time;
	} );
	sortedImages.push( { date, images } );
} );

sortedImages.sort( function( a, b ) {
	return b.date - a.date;
} );


const app = new Vue({
	render: h => h(App),
	data: {
		images: sortedImages
	},
})

app.$mount('#app')
