<template>
	<section class="grid-wrapper">
		<div class="grid">
			<WeatherBox :data="data"/>
			<template v-for="( imageData ) in gallery">
				<!-- <div class="grid-item date" :class="'date_' + (i % 2)" :key="imageData.date">
					<div class="text">
						<h1>{{ imageData.date | formatDate('D') }}</h1>
						<h2>{{ imageData.date | formatDate('MMMM YYYY') }}</h2>
					</div>
				</div> -->
				<div class="grid-item" v-for="(image, j) in imageData.images" :key="imageData.date + '_' + j">
					<figure>
						<img :src="image.src" />
						<figcaption>{{image.date | formatDate}} {{image.time | formatTime}} </figcaption>
					</figure>
				</div>
			</template>
		</div>
	</section>
</template>
<script>
export default {
	props: ['data','gallery'],
	methods: {},
	mounted () {
	console.log(this.gallery);
	},
	data () {
		return {}
	}

}
</script>
<style scoped lang='sass' src="./style.sass"></style>
