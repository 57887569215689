<template>
	<footer>
		<div class="wrapper">
			<p>{{ data.footerText }}</p>
		</div>
	</footer>
</template>
<script>
export default {
	props: {
		data: {
			type: Object
		}
	},
	methods: {},
	data () {
		return {}
	}

}
</script>
<style scoped lang='sass' src="./style.sass"></style>
