<template>
	<header>
		<div class="text">
			<h1>{{data.title}}</h1>
			<p>{{data.intro}}</p>
		</div>
		<WeatherBox :data="data"/>
	</header>
</template>
<script>
export default {
	props: {
		data: {
			type: Object
		}
	},
	methods: {},
	data () {
		return {}
	}

}
</script>
<style scoped lang='sass' src="./style.sass"></style>
